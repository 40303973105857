<template>
  <div class="add-public-job-external">
    <div class="container">
      <h5 class="text-primary m-1 pt-1 pb-2">{{isEditJob ? 'Edit Public Job' :'Add Public Job'}}</h5>
      <ValidationObserver ref="addPublicJobInfo" v-slot="{ handleSubmit }">
        <form id="publicJobCreate" @submit.prevent="handleSubmit()">
          <CCardGroup class="has-loading-overlay">
            <CCard>
              <CCardBody class="p-3">
                <h5 class="text-primary">Job Information</h5>
                <CRow>
                  <CCol md="6" class="mb-3">
                    <CRow class="row">
                      <label class="required col-lg-12 col-md-12"
                        >Your Public Job Board Link</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="public_job_board_id_link"
                            :value="jobs.public_job_board_id_link"
                            @input="handleInput"
                            :error="errors[0]"
                            :disabled="isEditJob"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="6" class="mb-3">
                    <CRow class="row">
                      <label class="required col-lg-12 col-md-12"
                        >Your Login Link</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="public_job_board_link"
                            :value="jobs.public_job_board_link"
                            @input="handleInput"
                            :error="errors[0]"
                            :disabled="isEditJob"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="6" class="mb-3">
                    <CRow class="row">
                      <label class="required col-lg-12 col-md-12"
                        >Publish from</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <CInput
                            name="sharing_start_date"
                            type="date"
                            class="m-0"
                            :value="jobs.sharing_start_date"
                            @input="handleInputDate('sharing_start_date', $event)"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="6" class="mb-3">
                    <CRow class="row">
                      <label class="required col-lg-12 col-md-12"
                        >Publish until</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <CInput
                            name="sharing_end_date"
                            type="date"
                            class="m-0"
                            :value="jobs.sharing_end_date"
                            @input="handleInputDate('sharing_end_date', $event)"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCardGroup>
          <div class="border-top d-flex justify-content-between py-3 flex-wrap mb-4">
          <div class="btn-add-job-submit">
            <button
              name="next"
              class="btn rounded-0 px-5 btn-outline-primary btn-lg mt-3 mr-3"
              type="button"
              @click="onCancelClicked"
              :disabled="isLoading"
            >
            Cancel
            </button>
            <button
              v-if="!isEditJob"
              name="next"
              class="btn rounded-0 px-5 btn-primary btn-lg mt-3"
              type="button"
              @click="onSaveClicked"
              :disabled="isLoading"
            >
              Save
              <CSpinner
                v-if="isLoading"
                class="spinner-border-sm text-white m-1"
              />
            </button>
            <button
              v-if="isEditJob"
              name="next"
              class="btn rounded-0 px-5 btn-primary btn-lg mt-3"
              type="button"
              @click="onUpdateClicked"
              :disabled="isLoading"
            >
              Update
              <CSpinner
                v-if="isLoading"
                class="spinner-border-sm text-white m-1"
              />
            </button>
          </div>
        </div>
        </form>
      </ValidationObserver>
      <CModal id="Preview Job" centered:true :show.sync="publishModal" size="lg">
      <template #header-wrapper>
        <header class="modal-header" style="background: #dd3651; color: #fff">
          <h5
            class="modal-title px-3"
            style="font-size: 26px; font-weight: 300"
          >
            Preview Job
          </h5>
          <button
            @click="onCancelPublish"
            type="button"
            aria-label="Close"
            class="close"
          >
            ×
          </button>
        </header>
      </template>
      <template #body-wrapper>
        <jobPreview :jobDetails="jobDetails"></jobPreview>
      </template>
      <template #footer-wrapper>
        <div class="d-flex justify-content-between py-3 flex-wrap mx-2">
          <div class="btn-add-job-cancel">
            <button
              name="cancel"
              class="btn rounded-0 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg mr-2"
              @click="onCancelPublish"
            >
              Cancel
            </button>
          </div>
          <div class="btn-add-job-submit">
            <button
              name="publish_page"
              class="btn rounded-0 btn-primary btn-lg mt-3 px-4"
              @click="onPublishClicked()"
              :disabled="isLoading"
            >
              Confirm
              <CSpinner
                v-if="isLoading"
                class="spinner-border-sm text-white m-1"
              />
            </button>
          </div>
        </div>
      </template>
    </CModal>
    <Modal
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="modalCallBack"
      :closeOnBackdrop="false"
    />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import moment from "moment";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import DateInput from "@/components/reusable/Fields/DateInput";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";

import jobPreview from "@/components/JobDetailPreview/JobDetailPreviewUpdated.vue";
import Modal from "@/components/reusable/Modal";

import {
  Role,
  LOGIN_URL,
  getLocalOrgDetail,
} from "@/helpers/helper";

export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    DateInput,
    RadioButton,
    CheckBox,
    TimePicker,
    jobPreview,
    Modal
  },
  data() {
    return {
      jobs: {
        sharing_start_date: moment().format("YYYY-MM-DD"),
        sharing_end_date: moment().add(30, 'days').format("YYYY-MM-DD"),
      },
      payload: {
        sharing_start_date: moment().format("YYYY-MM-DD"),
        sharing_end_date: moment().add(30, 'days').format("YYYY-MM-DD"),
      },
      isLoading: false,
      publishModal: false,
      jobDetails: {},
      confirmModal: {
        modalColor: "primary",
        modalTitle: "Update Job",
        modalContent: "Updating process will fetch the latest details from the Parent Job and sync up!",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
    }
  },
  computed: {
    ...mapGetters([
      "getPublicJobIssuerBoard",
      "getSelectedPublicJobIssuerBoard",
    ]),
    isEditJob() {
      let pageName = "Edit Public Job by Job Issuer";
      return this.$router?.currentRoute?.name == pageName ? true : false;
    },
    getJobID() {
      return this.$router.currentRoute.params.id;
    }
  },
  methods: {
    ...mapActions(["showToast", "addPublicJobs", "fetchPublicJobByID", "updatePublicSharedJob"]),
    handleInput(name, value) {
        Vue.set(this.jobs, name, value);
        Vue.set(this.payload, name, value);
    },
    handleInputDate(name, value) {
        Vue.set(this.jobs, name, value);
        Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
    Vue.set(this.jobs, name, value);
    let code = value ? value.id || value.code || value : null;
    this.payload = {
      ...this.payload,
      [name]: code,
      };
    },
    createPublicURLFromJobBoardLink(jobUrl) {
      if (!jobUrl) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide the valid URL!",
        });
        return false;
      }
      let splittedArray = jobUrl.split("/");
      let domain = splittedArray[0] + "//" + splittedArray[2] + "/api/v1"
      let organisation_index = splittedArray.indexOf("org")
      let customer_uid, organisation_id, job_id = null;
      if (organisation_index != -1) {
        customer_uid = parseInt(splittedArray[organisation_index - 1])
        if (splittedArray[organisation_index + 1].includes("job_id")) {
          let organisation_job_array = splittedArray[organisation_index + 1].split('?job_id=')
          organisation_id = parseInt(organisation_job_array[0])
          job_id = parseInt(organisation_job_array[1])
        }
      }
      else if (organisation_index == -1 && jobUrl.includes("job_id")) {
        let organisation_job_array = jobUrl.split('?job_id=')
        customer_uid = parseInt(organisation_job_array[0].substr(-1))
        organisation_id = 7
        job_id = parseInt(organisation_job_array[1])
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide the valid URL (Select the specific job to share here)!",
        });
        return;
      }
      let returnResponse = {
        customer_uid,
        organisation_id,
        job_id,
        url: `${domain}/customer/${customer_uid}/job/id/${job_id}/public`
      }
      return returnResponse;
    },
    async onSaveClicked() {
      const isValid = await this.$refs.addPublicJobInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }

      let todate = new Date(moment().format("YYYY-MM-DD"));
      let sharing_start_date = new Date(this.jobs.sharing_start_date);
      let sharing_end_date = new Date(this.jobs.sharing_end_date);
      if (todate > sharing_start_date) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Select from today!",
        });
        return false;
      }
      if (sharing_start_date > sharing_end_date) {
        this.showToast({
          class: "bg-danger text-white",
          message: "'Publish from' is greater from 'Publish until'!",
        });
        return false;
      }

      let parsed_data = this.createPublicURLFromJobBoardLink(this.payload?.public_job_board_id_link);
      if (!parsed_data) {
        this.$router.back();
        return false;
      }

      // let requestPayload = {
      //   customer_uid, job_id, domain, organisation_id, 
      //   "public_job_url":this.payload.public_job_board_id_link
      // }
      this.isLoading = true;
      let requestPayload = {
        "public_job_url" : parsed_data['url'],
        customer_uid: parsed_data['customer_uid']
      }
      let response = await this.fetchPublicJobByID(requestPayload)
      if (response.status == 200) {
        this.jobDetails = response.data;
        this.publishModal = true;
        this.isLoading = false;
      }
    },
    redirectToHome() {
      this.$router.push({
        path: `/${getLocalOrgDetail()?.path_acronym}`
      });
    },
    onCancelPublish() {
      this.publishModal = false;
    },
    async onPublishClicked() {
      this.isLoading = true;
      let customer_uid = parseInt(`${getLocalOrgDetail()?.customer_uid}`)
      let organisation_uid = parseInt(`${getLocalOrgDetail()?.organisation_id}`)
      if (!organisation_uid) {
        organisation_uid = 7; // Danat Al Emarat Hospital For Women & Children L.L.C (DOH)
      }
      let organisation_name = this.jobDetails?.organisation.name
      let payload = {
        ...this.jobDetails,
        customer_uid,
        organisation_uid,
        "source_link": this.payload.public_job_board_id_link,
        "public_job_board_link": this.payload.public_job_board_link,
        "sharing_start_date": this.payload.sharing_start_date,
        "sharing_end_date": this.payload.sharing_end_date,
        organisation_name
      }
      delete payload['advertisement_location']
      let response = await this.addPublicJobs(payload)
      if (response.status == 200) {
        this.publishModal = false
        this.showToast({
          class: "bg-success text-white",
          message: "Shared to Job board!",
        });
        this.$router.back();
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "Can't able to share the Job now! Please try later!",
        });
        this.$router.back();
      }
      this.isLoading = false;
    },
    onCancelClicked() {
      this.$router.back();
    },
    onUpdateClicked() {
      let todate = new Date(moment().format("YYYY-MM-DD"));
      let sharing_start_date = new Date(this.jobs.sharing_start_date);
      let sharing_end_date = new Date(this.jobs.sharing_end_date);
      if (todate > sharing_start_date) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Select from today!",
        });
        return false;
      }
      if (sharing_start_date > sharing_end_date) {
        this.showToast({
          class: "bg-danger text-white",
          message: "'Publish from' is greater from 'Publish until'!",
        });
        return false;
      }
      this.isLoading = true;
      this.confirmModal.isShowPopup = true;
    },
    modalCallBack(action) {
      this.isLoading = false;
      let confirmDelete = "Confirm";
      if (action === confirmDelete) {
        this.isLoading = true;
        let parsed_data = this.createPublicURLFromJobBoardLink(this.payload?.public_job_board_id_link)
        if (!parsed_data) {
          this.$router.back();
          return false;
        }
        let payload = {
          "source_link": parsed_data['url'],
          "sharing_start_date": this.payload.sharing_start_date,
          "sharing_end_date": this.payload.sharing_end_date,
          "job_id": this.getSelectedPublicJobIssuerBoard?.job_id
      }
        this.updatePublicSharedJob(payload).then((response) => {
          this.isLoading = false;
          this.$router.back();
        }).catch((err) => {
          this.isLoading = false;
          this.$router.back();
        });
      }
      this.confirmModal.isShowPopup = false;
    },
  },
  mounted() {
    if (this.isEditJob) {
      Vue.set(this.jobs, 'public_job_board_id_link', this.getSelectedPublicJobIssuerBoard['source_link']);
      Vue.set(this.payload, 'public_job_board_id_link', this.getSelectedPublicJobIssuerBoard['source_link']);
      Vue.set(this.jobs, 'public_job_board_link', this.getSelectedPublicJobIssuerBoard['public_job_board_link']);
      Vue.set(this.payload, 'public_job_board_link', this.getSelectedPublicJobIssuerBoard['public_job_board_link']);
      Vue.set(this.jobs, 'sharing_start_date', this.getSelectedPublicJobIssuerBoard['sharing_start_date']);
      Vue.set(this.payload, 'sharing_start_date', this.getSelectedPublicJobIssuerBoard['sharing_start_date']);
      Vue.set(this.jobs, 'sharing_end_date', this.getSelectedPublicJobIssuerBoard['sharing_end_date']);
      Vue.set(this.payload, 'sharing_end_date', this.getSelectedPublicJobIssuerBoard['sharing_end_date']);
    }
  },
};
</script>

<style>
</style>